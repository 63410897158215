import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
    background-color: ${props => props.background};
    height: ${props => props.height};
    width: 100%;
    border-radius: calc(${props => props.height} / 2);
    box-shadow: 10px 10px 20px 6px #00000044;
    overflow: hidden;
`;

const Progress = styled.div`
    background-color: ${props => props.color};
    width: ${props => props.percent}%;
    height: ${props => props.height};
    border-radius: calc(${props => props.height} / 2);
    box-shadow: 10px 10px 20px 6px #00000044;
`;

export const ProgressBar = ({ percent, color, background, height }) => {
    if (!height) height = "100%";

    return (
        <ProgressBarContainer background={background} height={height}>
            <Progress color={color} percent={percent} height={height} />
        </ProgressBarContainer>
    );
};

