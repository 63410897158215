export const Bio = {
  name: "Ashutosh Gautam",
  roles: [
    "Software Engineer",
    "Competitive  Programmer",  
    "Android Developer",
    "Learner",
  ],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  github: "https://github.com/ashuthe1",
  resume:
    "",
  linkedin: "https://www.linkedin.com/in/ashuthe1",
  twitter: "https://twitter.com/ashuthe1x",
  insta: "https://www.instagram.com/ashutosh2x",
  facebook: "https://www.facebook.com/ashuthe1",
  mail: "ashutoshgautam.work@gmail.com",
  mailSend: "mailto:ashutoshgautam.work@gmail.com"
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "C/C++",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/cplusplus/cplusplus-original.svg",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "NodeJs",
        image: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/SkillsIcons%2Fnodejs-1-logo.png?alt=media&token=f9b17b5b-cf34-49bf-b6e1-bc75af55e107",
      },
      {
        name: "Golang",
        image: "https://skillicons.dev/icons?i=go",
      },
      {
        name: "Redis",
        image: "https://skillicons.dev/icons?i=redis",
      },
      {
        name: "Kafka",
        image: "https://skillicons.dev/icons?i=kafka",
      },
      {
        name: "Express Js",
        image: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/SkillsIcons%2FexpressJs.png?alt=media&token=4bc69a25-19e4-4971-a531-0dc704adf871 ",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      // {
      //   name: "Kotlin",
      //   image:
      //     "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      // },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      // {
      //   name: "MySQL",
      //   image:
      //     "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      // },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      // {
      //   name: "Redux",
      //   image:
      //     "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      // },
      {
        name: "Git",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/git/git-original.svg",
      },
      {
        name: "AWS",
        image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
    ],
  },
];

export const experiences = [
  // {
  //   id: 0,
  //   img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Experience%2FEnterpretLogo.jpeg?alt=media&token=a6de690c-ff00-4f96-a6cc-17b2253d9601",
  //   role: "Backend Engineer Intern",
  //   company: "Enterpret",
  //   date: "July 2024 - Present",
  //   desc: "• Joined as Backend Developer, with team of 10 members.",
  //   skills: [
  //     "Golang",
  //     "GraphQL",
  //     "Serverless/AWS Lambda",
  //   ],
  //   // doc: "https://media.licdn.com/dms/image/D4D2DAQFlp60ZqHuaFQ/profile-treasury-image-shrink_1280_1280/0/1691180828512?e=1692381600&v=beta&t=mM5Y_NE5EPlQhez5FAN6NLVSKcO_Ojt_9Gq3mnFGkAQ",
  // },
  {
    id: 1,
    img: "https://assets.datamation.com/uploads/2021/12/Cisco-logo.png",
    role: "Software Engineer Intern",
    company: "Cisco",
    date: "May 2024 - June 2024",
    desc: "• Developed scripts using golang ast package, to perform codebase analysis and migrating swagger annotations to openapi3 reducing manual effort and streamlining the upgrade process. • Enhanced error messages for workflow submissions to Xchange, with clearer and more actionable feedback. • Integrated Llama3 model to e-commerce ticketing workflow, automating ticket classification into 4 categories and triggering specific events based on results to enhance operational efficiency",
    skills: [
      "Golang",
      "LLama3",
      "MongoDB",
    ],
    // doc: "https://media.licdn.com/dms/image/D4D2DAQFlp60ZqHuaFQ/profile-treasury-image-shrink_1280_1280/0/1691180828512?e=1692381600&v=beta&t=mM5Y_NE5EPlQhez5FAN6NLVSKcO_Ojt_9Gq3mnFGkAQ",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Experience%2FProElevate.png?alt=media&token=59262379-d2d4-4735-bf25-8fb33dcccb4a",
    role: "Backend Developer Intern",
    company: "ProElevate",
    date: "Feb 2024 - Apr 2024",
    desc: "• Developed a comprehensive educational platform offering resources, hackathons, and career opportunities with a referral portal, serving over 70,000+ registered users. • Reduced latency of the most resource-intensive operations by 40% through effective indexing and Redis caching. Additionally, migrated non-critical tasks to asynchronous processing using RabbitMQ, ensuring a seamless user experience for high traffic volumes. • Utilized AWS for deploying web server on EC2 instances, storing images in S3 bucket, and distributing traffic across multiple instances using an Application Load Balancer, ensuring high availability and scalability of the platform.",
    skills: [
      "Amazon Web Services(AWS)",
      "Redis",
      "RabbitMQ", 
      "NodeJs",
      "MongoDB",
    ],
    // doc: "https://media.licdn.com/dms/image/D4D2DAQFlp60ZqHuaFQ/profile-treasury-image-shrink_1280_1280/0/1691180828512?e=1692381600&v=beta&t=mM5Y_NE5EPlQhez5FAN6NLVSKcO_Ojt_9Gq3mnFGkAQ",
  },
  {
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Experience%2Frablo_logo.jpeg?alt=media&token=12f2ad21-292a-4370-a101-7dee5c74f1e7",
    role: "Backend Developer Intern",
    company: "Rablo Learning Pvt. Ltd.",
    date: "April 2023 - July 2023",
    desc: "• Developed Edtech Platform using agile methodologies within a fast-paced startup environment. • Led the development of four key modules: Dashboard, Student Enrollment, Payment Processing, and Reward Points. • Implemented geospatial indexes to efficiently locate tutors within 5KM radius, resulting in a 30% increase in platform engagement.",
    skills: [
      "NodeJS",
      "Redis",
      "ExpressJS",
      "MongoDB", 
    ],  
    // doc: "https://fire basestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/Screenshot%20from%202023-05-28%2023-20-46.png?alt=media&token=5570f995-e8f4-4f12-bb2f-73bcf4b20730",
  },
  // {
  //   id: 3,
  //   img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Experience%2FScaleAILogo.png?alt=media&token=fb8cc99d-d7e7-49d0-9e69-75c8e11a958c",
  //   role: "Freelance AI Trainer",
  //   company: "Scale AI",
  //   date: "Sep 2023 - Dec 2023",
  //   desc: "• Evaluated LLM Models performance in comprehending, debugging, and generating code snippets.  • Created comprehensive Unit tests to ensure the reliability and functionality of generated code.",
  //   skills: [
  //     "Algorithms",
  //     "Data Structures",
  //     "Java",
  //   ],  
  // },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Education%2FBMSCE.jpeg?alt=media&token=28d899a6-a802-479d-b7f4-b4313aeafaae",
    school: "BMS College of Engineering, Bangalore",
    date: "Oct 2021 - June 2025",
    grade: "8.34 CGPA",
    desc: "I am currently pursuing Bachelor's degree in Information Technology at BMSCE. CourseWork: Object-Oriented Programming, Database Management System, Operating System and Computer Networks, among others.",
    degree: "Bachelor of Engineering, Information Technology",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Education%2FMPBSE.jpeg?alt=media&token=56b263ea-7d0c-4b34-bc7c-bd5bdd4d7bfd",
    school: "Kedar Prasad Higher Secondary School, Jaitwara, Madhya Pradesh",
    date: "July 2018 - August 2020",
    grade: "94% in 10th standard & 92% in 12th standard",
    desc: "Rank 1st in institute & secured meritorious scholorship from MP government.",
    degree: "MPBSE, Physics, Chemistry, Mathematics, Biology",
  },
];

export const projects = [
  {
    id: 0,
    title: "ProjectHub",
    // date: "Apr 2023 - May 2023",
    description:
      "Developed a MERN stack platform facilitating collaboration between project maintainers and Contributors. • Enabled users to search projects based on preferred tech stack and execute CRUD operations on their posts. • Enhanced project engagement through the implementation of comment, rating, and bookmark features. • Integrated Stripe API for payment and implemented an OTP-based forgot password feature for enhanced security. • Implemented IP-based rate-limiting and utilized redis caching to optimize performance and scalability.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2FProjectHub.png?alt=media&token=8db02255-52ad-4db9-aa71-6bf4666d1e11",
    tags: ["Redis" , "MongoDB", "Expresss", "React", "NodeJs", "Stripe", "Cloudinary"],
    category: "web app",
    github: "https://github.com/ashuthe1/ProjectHub",
    webapp: "https://projecthub.gautamashutosh.com/",
    // member: [
    //   {
    //     name: "Ashutosh Gautam",
    //     img: "https://avatars.githubusercontent.com/u/64485885?v=4",
    //     linkedin: "https://www.linkedin.com/in/Ashutosh-chanda-b89a791b3/",
    //     github: "https://github.com/Ashutoshchanda/",
    //   },
    //   {
    //     name: "Upasana Chaudhuri",
    //     img: "https://avatars.githubusercontent.com/u/100614635?v=4",
    //     linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
    //     github: "https://github.com/upasana0710",
    //   },
    // ],
  },
  {
    id: 1,
    title: "StreamX",
    date: "Dec 2021 - Apr 2022",
    description:
      "• Distributed system for chat platform using Golang microservices, optimizing performance and scalability through Nginx load balancing and Redis Pub/Sub messaging • Implemented high-throughput, low-latency message processing with Kafka, enabling efficient message streaming and batch processing by Golang consumers, ensuring seamless real-time communication.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bestfriends-7cfa8.appspot.com/o/ProjectHub%2Fdiagrams_image.png?alt=media&token=93a89be0-8cf1-4af7-ac07-43d6a319b24f",
    tags: ["Golang", "Kafka", "Redis", "Postgres", "Nginx", "Docker", "React"],
    category: "android app",
    github: "https://github.com/ashuthe1/StreamX-Distributed-Chat",  
    webapp: "https://github.com/ashuthe1/StreamX-Distributed-Chat",
  },
  {
    id: 2,
    title: "CampusPing!",
    date: "Dec 2021 - Apr 2022",
    description:
      "• Dynamic android app connecting college students based on shared interests and skills. • Features: Google and Email Login, Customise Profile, Send, Cancel and Accept Request, User Chat. • Technologies used: Jetpack Navigation Component, Fragments, Kotlin Coroutines - Multi Threading, ViewBinding, Caching data with Shared ViewModel, Swipeable Cards UI, 10+ Beta testers",
    image:
      "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2FCampusPing2.png?alt=media&token=fdc1298c-eeb8-4e67-b85d-af36bde78f19",
    tags: ["Kotlin", "Google Firebase", "Cloud Firestore", "Multithreading"],
    category: "android app",
    github: "https://github.com/ashuthe1/CampusPing",  
    webapp: "https://www.youtube.com/watch?v=OYwJ4GUnX2M&feature=youtu.be",
  },
  {
    id: 3,
    title: "VoteKaroIndia",
    date: "Jun 2023 - Jul 2023",
    description:
      "A decentralized voting application built on Ethereum Blockchain using Solidity and React",
    image:
      "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2Fblockchain-voting-main-1600.jpg?alt=media&token=eba39c97-131c-4299-949d-f760672ac09f",
    tags: ["Solidity", "Ether.js", "React.js", "Node.js"],
    category: "web app",
    github: "https://github.com/ashuthe1/VoteKaroIndia",
    webapp: "https://github.com/ashuthe1/VoteKaroIndia",
  },
  {
    id: 4,
    title: "Fitness Coach",
    date: "Oct 2022 - Present",
    description:
      "Fitness webapp to show details and instructions about gym exercises. Browse and search hundreds of exercises with practical examples, details, YouTube videos, and related exercises.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2FHealthCoach.avif?alt=media&token=83ca0687-69ff-43e1-8615-eee95ae55182",
    tags: [
      "React Js",
      "ExerciseDB API",
      "YouTube API",
    ],
    category: "web app",
    github: "https://fitnesscoach.vercel.app/",
    webapp: "https://fitnesscoach.vercel.app/",
  },
  {
    id: 5,
    title: "College Buddy",
    date: "Oct 2022 - Present",
    description: "Website for college students offering Educational resources, CGPA calculator, Career Oportunities & Upcoming Events.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2FcollegeBuddy.avif?alt=media&token=bad7d214-c3d5-4b90-a4a5-ced74249ec06",
    tags: [
      "HTML",
      "CSS",
      "JavaScript",
    ],
    category: "web app",
    github: "https://fitnesscoach.vercel.app/",
    webapp: "https://fitnesscoach.vercel.app/",
  },
  {
    id: 6,
    title: "CodeView",
    date: "Oct 2022 - Present",
    description: "Online code editor that allows users to write and preview HTML, CSS, and JavaScript code",
    image: "https://firebasestorage.googleapis.com/v0/b/developerportfolio-3eb05.appspot.com/o/Projects%2FCodeView.png?alt=media&token=09c53bde-1866-416f-a1ef-f00c5a52551b",
    tags: [
      "React",
      "CodeMirror",
    ],
    category: "web app",
    github: "https://github.com/ashuthe1/CodeView",
    webapp: "https://codepreview.vercel.app/",
  },
];
