import styled from "styled-components"

const Wrapper = styled.a`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    padding: 24px;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid black;
    transition: all 250ms ease-in-out;
    box-shadow: 10px 10px 20px 6px #00000044;

    &:hover {
        box-shadow: 20px 20px 40px 12px #00000066;
    }
`

const ProfileTitle = styled.div`
    font-size: 32px;
    color: hsl(266, 100%, 65%);
    color: black;
`
const ProfileSubTitle = styled.div`
    font-size: 14px;
    color: hsl(0, 0%, 35%)
    color: black;
`
const ProfileDescription = styled.div`
    font-size: 16px;
    text-transform: capitalize;
    color: black;
`
const ProfileValue = styled.div`
    font-size: 20px;
    color: hsl(266, 100%, 60%);
    color: black;
`

export const ProfileLink = ({ link, title, subtitle, description, value }) => {
    return (
        <Wrapper href={link} target="_blank" rel="noopener noreferrer">
            <ProfileTitle>{title}</ProfileTitle>
            <ProfileSubTitle>{subtitle}</ProfileSubTitle>
            <ProfileDescription>{description}</ProfileDescription>
            <ProfileValue>{value}</ProfileValue>
        </Wrapper>
    )
}