  import React from 'react'
  import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
  import { DiCssdeck } from 'react-icons/di';
  import { FaBars } from 'react-icons/fa';
  import { Bio } from '../../data/constants';
  import { Close, CloseRounded, LightMode } from '@mui/icons-material';
  import { DarkMode } from '@mui/icons-material'
  import { useTheme } from 'styled-components';
  import styled from "styled-components";
  import LightModeIcon from '@mui/icons-material/LightModeOutlined';

const DarkText = styled.div`
  size: 2px;
  color: black;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`
const LightText = styled.div`
  size: 2px;
  color: white;
  :hover {
    color: ${({ theme }) => theme.primary};
  }
`
  const Navbar = ({ theme, setTheme }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    function changeToDarkMode() {
      // setTheme(true)
      console.log('Dark Mode')
    }
    function changeToLightMode() {
      // setTheme(false)
      console.log('Light Mode')
    }

    return (
      <Nav>
        <NavbarContainer>
          <NavLogo to='/'>
          <a style={{ display: "flex", alignItems: "center", color: theme === 'darkMode' ? 'white' : 'black', marginBottom: '20;', cursor: 'pointer' }}>
            <Span>☕/Ashutosh Gautam</Span> 
          </a>
          </NavLogo>
          <MobileIcon>
            <FaBars onClick={() => {
              setIsOpen(!isOpen)
            }} />
          </MobileIcon>
          <NavItems>
            <NavLink href="#about">About</NavLink>
            <NavLink href='#skills'>Skills</NavLink>
            <NavLink href='#experience'>Experience</NavLink>
            <NavLink href='#projects'>Projects</NavLink>
            <NavLink href='#codingProfiles'>CodingProfiles</NavLink>
            <NavLink href='#education'>Education</NavLink>
          </NavItems>
          {theme === 'lightMode' &&<ButtonContainer onClick={changeToDarkMode}>
            <DarkText>Dark Mode</DarkText> 
            <DarkMode/>
          </ButtonContainer>}
          {theme === 'darkMode' && <ButtonContainer onClick={changeToLightMode}>
            <LightText>Light Mode</LightText>
            <LightModeIcon color="primary"/>
          </ButtonContainer>}
          {
            isOpen &&
            <MobileMenu isOpen={isOpen}>
              <MobileLink href="#about" onClick={() => {
                setIsOpen(!isOpen)
              }}>About</MobileLink>
              <MobileLink href='#skills' onClick={() => {
                setIsOpen(!isOpen)
              }}>Skills</MobileLink>
              <MobileLink href='#experience' onClick={() => {
                setIsOpen(!isOpen)
              }}>Experience</MobileLink>
              <MobileLink href='#projects' onClick={() => {
                setIsOpen(!isOpen)
              }}>Projects</MobileLink>
              <MobileLink href='#codingProfiles' onClick={() => {
                setIsOpen(!isOpen)
              }}>CodingProfiles</MobileLink>
              <MobileLink href='#education' onClick={() => {
                setIsOpen(!isOpen)
              }}>Education</MobileLink>
              {theme === 'lightMode' && <GitHubButton onClick={changeToDarkMode}> 
                <DarkMode/>
                <DarkText>DarkMode</DarkText>
              </GitHubButton>}
              {theme === 'darkMode' && <GitHubButton onClick={changeToLightMode}> 
                <LightModeIcon/>
                <LightText>LightMode</LightText>
              </GitHubButton>}
            </MobileMenu>
          }
        </NavbarContainer>
      </Nav>
    )
  }

  export default Navbar