import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const rollUpAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(+70%) rotateX(-90deg) scale3d(0.9, 0.9, 0.9);
    }
    30% {
        opacity: 1;
        transform: translateY(0) rotateX(0) scale3d(1, 1, 1);
    }
    70% {
        opacity: 1;
        transform: translateY(0) rotateX(0) scale3d(1, 1, 1);
    }
    100% {
        opacity: 0;
        transform: translateY(-70%) rotateX(90deg) scale3d(0.9, 0.9, 0.9);
    }
`;

const Textt = styled.div`
    display: inline-block;
    font-size: 48px;
    font-weight: 200;
    letter-spacing: 0;
    text-align: center;
    animation: ${rollUpAnimation} 1.5s ease-in-out forwards;

    @media (max-width: 600px){
        font-size: 36px;
    }

    @media (max-width: 400px){
        font-size: 28px;
    }
`;

export const RollingText = ({ sentences }) => {
    
    const [sentence, setSentence] = useState(sentences[0])

    useEffect(() => {
        let index = 0;
        const interval = setInterval(() => {
            index = (index + 1) % sentences.length;
            setSentence(sentences[index])
        }, 1500);

        return () => clearInterval(interval);
    }, [sentences])

    return (
        <Textt key={sentence}>{sentence}</Textt>
    )
}