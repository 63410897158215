  import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider, ProjectButton } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'

const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState(false);
  const [buttonText, setButtonText] = useState("Show All Projects");
  function changeToggle(e) {
    setToggle(!toggle);
    if(buttonText == 'Show All Projects') setButtonText('Show Featured Projects');
    else setButtonText('Show All Projects');
  }
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          I have worked on a wide range of projects. From web apps to android apps. Here are some of my projects.
        </Desc>
        <CardContainer>
          {toggle && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {!toggle && projects
            .filter((project) => project.id < 3)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
        <ProjectButton onClick={changeToggle}> {buttonText} </ProjectButton>
      </Wrapper>
    </Container>
  )
}

export default Projects