import { ProfileLinkProps } from "./ProfileLink";
import variables from './variables.json';
export const profiles = [
    {
        link: "https://www.codechef.com/users/ashuthe1x",
        title: "CodeChef",
        subtitle: "Competitive Programming",
        description: `
        5🌟 Codechef
        • Rating: ${variables.codechef.maxRating} (max)
        • Top 0.7% among all active users worldwide.`,
        value: "@ashuthe1x"
    },
    // {
    //     link: "https://codeforces.com/profile/ashuthe1x",
    //     title: "CodeForces",
    //     subtitle: "Competitive Programming",
    //     description: `${variables.codeforces.rank}, Max Rating: ${variables.codeforces.maxRating}`,
    //     value: "@ashuthe1x"
    // },
    {
        link: "https://leetcode.com/ashuthe1/",
        title: "LeetCode",
        subtitle: "DSA Practice",   
        description: `Guardian - Max Rating: ${Math.round(variables.leetcode.maxContestRating)}• Top 0.8% among all active users worldwide.`,
        value: "@ashuthe1"
    },
    {
        link: "https://github.com/ashuthe1",
        title: "GitHub",
        subtitle: "Software Project Management",
        description: "Stars: 30+, Public Repo: 40+",
        value: "@ashuthe1"
    },
    // {
    //     link: "https://www.hackerearth.com/@ashuthe1",
    //     title: "HackerEarth",
    //     subtitle: "Hackathons & Contest",
    //     description: `• Global rank : 627/113979
    //     • Top 0.5% among all Rated participants worldwide`,
    //     value: "@ashuthe1"
    // },
    // {
    //     link: "https://auth.geeksforgeeks.org/user/ashuthe1/profile",
    //     title: "GeeksforGeeks",
    //     subtitle: "DSA Practice",
    //     description: `Total Problems Solved: ${variables.gfg.problemsCount}`,
    //     value: "@ashuthe1"
    // }
]