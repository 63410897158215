import { ProgressBar } from "./ProgressBar";
import styled from "styled-components";
import { ratings } from "./programming";
import totalProblems from "./totalProblems";
import variables from "./variables.json"
import { PublicProfile } from "./PublicProfile";


const Wrapper = styled.section`
    background: #654ea3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #eaafc8, #654ea3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #eaafc8, #654ea3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const SectionTitle = styled.div`
    padding: 72px 0;
    font-weight: 700;
    font-size: 64px;
    text-align: center;

    @media (max-width: 650px) {
        font-size: max(10vw, 32px);
        padding: max(10vw, 32px) 0;
    }
`
const SectionHighlight = styled.div`
    padding: 72px 0;
    text-align: center;
    font-weight: 400;
    font-size: 64px;
    
    @media (max-width: 650px) {
        font-size: max(10vw, 32px);
        padding: max(10vw, 32px) 0;
    }
`

const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    max-width: 700px;
    margin: auto;
`
const ProblemStats = styled.a`
    display: flex;
    align-items: center;
    gap: 24px;
    cursor: pointer;
    @media (max-width: 650px) {
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        padding: 0 32px;
    }
`
const StatsText = styled.div`
    flex: 0 1 400px;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: 650px) {
        flex: auto;
    }
`
const PlatformName = styled.div`
    font-size: 20px;
`
const ProblemsCount = styled.div`
    font-size: 20px;
`
const StatsDescription = styled.div`
    text-align: center;
    font-size: 20px;
`
export const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

export const ProblemSolving = () => {
    ratings.sort((a, b) => b.problemsCount - a.problemsCount);
    const maxCount = ratings[0].problemsCount * 1.1;

    // remove small counts and display other instead
    const modifiedRatings = []
    const totalProblemActualCount = ratings.reduce((currentTotal, b) => currentTotal + b.problemsCount, 0);
    let currentProblemCount = 0;
    let otherPlatformProblemsCount = 0;
    for (let i = 0; i < ratings.length; i++) {
        const platform = ratings[i];
        if(currentProblemCount < totalProblemActualCount * 0.8) {
            modifiedRatings.push(platform)
        }
        else {
            otherPlatformProblemsCount += platform.problemsCount;
        }
        currentProblemCount += platform.problemsCount;
    }
    if(otherPlatformProblemsCount > 0) {
        modifiedRatings.push({
            platform: "Other Platforms",
            problemsCount: otherPlatformProblemsCount,
        })
    }

    return (
        <Wrapper id="codingProfiles">
            <SectionTitle>Coding Profiles</SectionTitle>
            <PublicProfile/>
            <StatsContainer>
                <StatsDescription>
                    <strong>{totalProblems()}+</strong> Quality Problems solved on Various Platforms
                </StatsDescription>
                {modifiedRatings.map(rating => {
                    return (
                        <ProblemStats key={rating.platform} href={rating.profileLink} rel="noopener noreferrer" target="_blank">
                            <StatsText>
                                <PlatformName>{rating.platform}</PlatformName>
                                <ProblemsCount>{rating.problemsCount}</ProblemsCount>
                            </StatsText>
                            <ProgressBar color="hsla(271, 100%, 50%, 1)" background="hsl(0, 0%, 100%)" percent={rating.problemsCount / maxCount * 100} height="20px" />
                        </ProblemStats>
                    )
                })}
            </StatsContainer>
            <SectionHighlight>{variables.codechef.stars} on CodeChef</SectionHighlight>
            
        </Wrapper>
    )
}