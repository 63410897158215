import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import { ProblemSolving } from "./components/ProblemSolving/ProblemSolving.js";
import { HomeHeader } from "./components/Intro/HomeHeader.js";
import { RollingText } from "./components/Intro/RollingText.js";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
const ilike = [
  "I like Travelling!",
  "I like Exploring!",
  "I like Music!",
  "I like Learning!",
]

const LikesContainer = styled.div`
  display: flex;
  padding: 100px 0;
  justify-content: center;
  align-content: center;
`

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (  
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router > 
        <Navbar theme={darkMode ? 'darkMode' : 'lightMode'} setTheme={setDarkMode} />
        <Body>
          <HomeHeader />
          <LikesContainer>
                <RollingText sentences={ilike}/>
            </LikesContainer>
          <Wrapper>
            <Skills />
            <Experience />
          </Wrapper>
          <Projects openModal={openModal} setOpenModal={setOpenModal} />
          <ProblemSolving />
          <Wrapper>
            <Education />
            <Contact />
          </Wrapper>
          <Footer />
          {openModal.state &&
            <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />
          }
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
