import styled from "styled-components"

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 24px;
    gap: 9px;
    cursor: default;
    border: 2px solid hsl(0, 0%, 95%);
    box-shadow: 10px 10px 20px 6px #00000011;
    border-radius: 16px;
    &:hover {
        box-shadow: 10px 10px 20px 6px #00000066;
    }
`

const Title = styled.div`
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 600px) {
        font-size: max(4vw, 16px);
    }
`

const Data = styled.div`
    font-size: 40px;
    text-align: center;
    color: hsl(266, 100%, 60%);
`

export const Highlight = ({ title, data }) => {
    return (
        <Container>
            <Data>{data}</Data>
            <Title>{title}</Title>
        </Container>
    )
}